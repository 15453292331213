import { app } from "@/constants/app.js"
import Vue from 'vue'
import Router from 'vue-router'
import Vuex from '@/store/index.js'
import { permissions } from '../constants/permissions.js?version=2.5.16'
import notif from '@/utils/notif'
import i18n from '../i18n'

const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Home',
          component: Dashboard,
          meta: {}
        },
        {
          path: 'dashboard/admin',
          name: 'Home Admin',
          //    component: HomeAdmin,
          meta: { permissions: permissions.read_organizations },
          component: async () => {
            const component = await import('@/components/Dashboard/Admin/HomeAdmin')
            return component
          }
        },
        {
          path: 'dashboard/opto',
          name: 'Home Opto',
          //    component: HomeOpto,
          meta: { permissions: permissions.read_opto_patients },
          component: async () => {
            const component = await import('@/components/Dashboard/Opto/HomeOpto')
            return component
          }
        },
        {
          path: 'dashboard/practitioner',
          name: 'Home Practitioner',
          //    component: HomePractionner,
          meta: { permissions: permissions.read_patients },
          component: async () => {
            const component = await import('@/components/Dashboard/Practionner/HomePractionner')
            return component
          }
        },
        {
          path: 'user-manuals',
          name: 'User Manuals',
          //    component: UserManuals,
          meta: {},
          component: async () => {
            const component = await import('@/views/Client/Manuals/UserManuals')
            return component
          }
        },
        {
          path: '/algo-designer/folder',
          name: 'Algodesigner',
          //    component: AlgoManager,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/AlgoManager')
            return component
          }
        },
        {
          path: '/algo-designer/folder/:folder_id',
          name: 'Algodesigner',
          //    component: AlgoManager,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/AlgoManager')
            return component
          }
        },
        {
          path: '/algo-designer',
          name: 'Algodesigner algo',
          //    component: ValgoDesigner,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/ValgoDesigner')
            return component
          }
        },
        {
          path: '/algo-designer/:id',
          name: 'Algodesigner algo edit',
          //    component: ValgoDesigner,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/ValgoDesigner')
            return component
          }
        },
        {
          path: '/unit-test',
          name: 'unit test',
          //component: MainInterface,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/MainInterface')
            return component
          }
        },
        {
          path: '/unit-test/create/:folder',
          name: 'create unit test',
          //component: CreateTest,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/CreateTest')
            return component
          }
        },
        {
          path: '/unit-test/edit/:id',
          name: 'edit unit test',
          //    component: CreateTest,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/CreateTest')
            return component
          }
        },
        {
          path: '/unit-test/duplicate/:id',
          name: 'duplicate unit test',
          //component: CreateTest,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/CreateTest')
            return component
          }
        },
        {
          path: '/unit-test/test_cases',
          name: 'test cases',
          //component: TestCases,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/TestCases')
            return component
          }
        },
        {
          path: '/test-cases',
          name: 'Test Designer',
          //component: TestCaseManager,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/TestCaseManager')
            return component
          }
        },
        {
          path: '/test-cases/:folder_id',
          name: 'Test Designer',
          //component: TestCaseManager,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/TestCaseManager')
            return component
          }
        },
        {
          path: '/unit-test/:id',
          name: 'testing',
          //    component: MainInterface,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/MainInterface')
            return component
          }
        },
        {
          path: '/unit-test/consultation/:consultation_id',
          name: 'Consultation  steps',
          //    component: MainInterface,
          meta: { permissions: permissions.read_algos },
          component: async () => {
            const component = await import('@/views/Admin/AlgoDesigner/UnitTestInterface/MainInterface')
            return component
          }
        },
        {
          path: '/profil',
          name: 'Profil',
          //component: Profil,
          meta: {},
          component: async () => {
            const component = await import('@/views/Client/auth/Profil')
            return component
          }
        },
        {
          path: '/logs',
          name: 'Logs',
          //component: Logs,
          meta: { permissions: permissions.read_logs },
          component: async () => {
            const component = await import('@/views/Admin/Logs/Logs')
            return component
          }
        },
        {
          path: '/logs/:id',
          name: 'Log',
          //component: Log,
          meta: { permissions: permissions.read_logs },
          component: async () => {
            const component = await import('@/views/Admin/Logs/Log')
            return component
          }
        },
        {
          path: '/boxes',
          name: 'Boxes',
          //component: Boxes,
          meta: { permissions: permissions.read_boxes },
          component: async () => {
            const component = await import('@/views/Admin/Boxes/Boxes')
            return component
          }
        },        {
          path: '/boxesv1',
          name: 'Boxesv1',
          //component: Boxes,
          meta: { permissions: permissions.read_boxes },
          component: async () => {
            const component = await import('@/views/Admin/Boxes/BoxesV1')
            return component
          }
        },
        {
          path: '/boxes/Map',
          name: 'Boxes Map',
          //component: Boxes,
          meta: { permissions: permissions.read_boxes },
          component: async () => {
            const component = await import('@/views/Admin/Boxes/Boxes')
            return component
          }
        },
        {
          path: '/admin/boxes/v1/:id',
          name: 'Box',
          //component: Box,
          meta: { permissions: permissions.read_boxes },
          component: async () => {
            const component = await import('@/views/Admin/Boxes/BoxV1')
            return component
          }
        },
        {
          path: '/admin/patients/:patient/consultations/:consultation',
          name: 'Consultation opto',
          //    component: Consultation,
          props: true,
          meta: {},
          component: async () => {
            const component = await import('@/views/Client/Consultations/Consultation')
            return component
          }
        },
        {
          path: '/admin/organisations',
          name: 'Organisations',
          //    component: Organisations,
          meta: {},
          component: async () => {
            const component = await import('@/views/Admin/Organisations/Organisations')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/:tabs?',
          name: 'Organisation',
          //component: Organisation,
          meta: { permissions: permissions.read_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Organisations/Organisation')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/:edit',
          name: 'Edit organisation',
          //    component: Organisation,
          meta: { permissions: permissions.update_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Organisations/Organisation')
            return component
          }
        },
        {
          path: '/admin/organisations/create',
          name: 'Add organisation',
          //component: AddOrganisation,
          meta: { permissions: permissions.create_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Organisations/AddOrganisation')
            return component
          }
        },
        {
          path: '/admin/organisations/create/:id',
          name: 'Add organisation_child',
          //component: AddOrganisation,
          meta: { permissions: permissions.create_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Organisations/AddOrganisation')
            return component
          }
        },
        {
          path: '/admin/organizations/:id/shop/create',
          name: 'Add Shop',
          //component: AddShop,
          meta: { permissions: permissions.create_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Shops/AddShop')
            return component
          }
        },
        {
          path: '/admin/shops',
          name: 'Shops',
          //    component: Shops,
          meta: { permissions: permissions.read_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Shops/Shops')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/shops/:id/:tabs?',
          name: 'Shop',
          //component: Shop,
          meta: { permissions: permissions.read_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Shops/Shop')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/shops/:id/:edit',
          name: 'Edit shop',
          //component: Shop,
          meta: { permissions: permissions.update_organizations },
          component: async () => {
            const component = await import('@/views/Admin/Shops/Shop')
            return component
          }
        },
        {
          path: '/admin/subscriptions',
          name: 'Subcriptions',
          //component: Subscriptions,
          meta: { permissions: permissions.read_subscriptions },
          component: async () => {
            const component = await import('@/views/Admin/Subscriptions/Subscriptions')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/shops/:shop_id/subscriptions/:sub_id',
          name: 'Subcription',
          //component: Subscription,
          meta: { permissions: permissions.read_subscriptions },
          component: async () => {
            const component = await import('@/views/Admin/Subscriptions/Subscription')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/shops/:shop_id/subscriptions/:sub_id/edit',
          name: 'Edit Subcription',
          //component: AddSubscription,
          meta: { permissions: permissions.update_subscriptions }
        },
        {
          path: '/admin/organizations/:orga_id/shops/:shop_id/subscription/create',
          name: 'Create subscription',
          //    component: AddSubscription,
          meta: { permissions: permissions.create_subscriptions },
          component: async () => {
            const component = await import('@/views/Admin/Subscriptions/AddSubscription')
            return component
          }
        },
        {
          path: '/admin/accounts',
          name: 'Accounts',
          //component: Accounts,
          meta: { permissions: permissions.read_users },
          component: async () => {
            const component = await import('@/views/Admin/Accounts/Accounts')
            return component
          }
        },
        {
          path: '/admin/organizations/:orga_id/accounts/:id/:tabs?',
          name: 'Account',
          //component: Account,
          meta: { permissions: permissions.read_users },
          component: async () => {
            const component = await import('@/views/Admin/Accounts/Account')
            return component
          }
        },
        {
          path: '/patients',
          name: 'Patients',
          //component: Patients,
          meta: { permissions: permissions.read_patients },
          component: async () => {
            const component = await import('@/views/Client/Patients/Patients')
            return component
          }
        },
        {
          path: '/patients/:id',
          name: 'Patient',
          //    component: Patient,
          meta: { permissions: permissions.read_patients },
          component: async () => {
            const component = await import('@/views/Client/Patients/Patient')
            return component
          }
        },
        {
          path: '/patients/:patient_id/consultations/:consultation_id',
          name: 'Consultation',
          //component: Consultation,
          props: true,
          meta: { permissions: permissions.read_patients },
          component: async () => {
            const component = await import('@/views/Client/Consultations/Consultation')
            return component
          }
        },
        {
          path: '/opto/patients/:patient_id/consultations/:consultation_id',
          name: 'Consultation opto',
          //component: Consultation,
          props: true,
          meta: { permissions: permissions.read_opto_patients },
          component: async () => {
            const component = await import('@/views/Client/Consultations/Consultation')
            return component
          }
        },
        {
          path: '/opto/patients/',
          name: 'Patients opto',
          //    component: PatientsOpto,
          props: true,
          meta: { permissions: permissions.read_opto_patients },
          component: async () => {
            const component = await import('@/views/Admin/Opto/PatientsOpto')
            return component
          }
        },
        {
          path: '/opto/patients/:patient_id',
          name: 'Patient consultation opto',
          //    component: PatientOpto,
          props: true,
          meta: { permissions: permissions.read_opto_patients },
          component: async () => {
            const component = await import('@/views/Admin/Opto/PatientOpto')
            return component
          }
        },
        {
          path: '/stats',
          name: 'Stats ',
          //component: StatsPractionner,
          meta: { permissions: permissions.read_patients },
          component: async () => {
            const component = await import('@/views/Client/Stats/StatsPractionner')
            return component
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      //component: Login,
      meta: { hideForAuth: true },
      component: async () => {
        const component = await import('@/views/Client/auth/Login')
        return component
      }
    },
    {
      path: '/password/forgot',
      name: 'forgot password',
      //    component: ForgotPassword,
      meta: { hideForAuth: true },
      component: async () => {
        const component = await import('@/views/Client/auth/ForgotPassword')
        return component
      }
    },
    {
      path: '/password/change',
      name: 'change password',
      //    component: ChangePassword,
      component: async () => {
        const component = await import('@/views/Client/auth/ChangePassword')
        return component
      }
    },
    {
      path: '/password/reset/:token',
      name: 'reset password',
      //    component: ResetPassword,
      props: true,
      meta: { hideForAuth: true },
      component: async () => {
        const component = await import('@/views/Client/auth/ResetPassword')
        return component
      }
    },
    {
      path: '/404',
      name: '404',
      //    component: Page404
      component: async () => {
        const component = await import('@/views/Page404')
        return component
      }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = 'SIVIEW Server ' + to.name

  if (document.querySelector('.c-body'))
    document.querySelector('.c-body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

  if (to.meta.fromApp) {
    if (!to.query.data) {
      next({ path: '/404' })
      return
    } else {
      next()
      return
    }
  }

  if (
    to.name != 'change password' &&
    localStorage.getItem('token') &&
    Vuex.state.authentification.user.change_password > 0
  ) {
    next({ name: 'change password' })
    return
  }

  if (to.meta.hideForAuth) {
    if (localStorage.getItem('token')) {
      next({ name: Vuex.state.authentification.roles[0] == 'Super Admin' ? 'Home ' + 'Admin' : 'Home' })
    } else {
      next()
    }
  } else {
    if (localStorage.getItem('token')) {
      next()
    } else {
      sessionStorage.setItem('url', to.path)
      next({ name: 'Login' })
    }
  }

  if (to.meta && to.meta.permissions) {
    if (Vuex.state.authentification.permissions.find(elem => elem === to.meta.permissions)) {
      next()
    } else {
      notif.showNotif(i18n.messages[i18n.locale].common_client.permissions_denied, 'error')
      next({ name: 'Home' })
    }
  }

  if (to.name == 'Home' && localStorage.getItem('token') && Vuex.state.authentification.roles[0] == 'Super Admin') {
    next({ name: 'Home ' + Vuex.state.authentification.roles[1] })
  }
})
export default router
